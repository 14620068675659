import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import logo from '../../assets/images/logobargat.jpeg'


const navigation = [
  { name: 'Home', href: './', current: true },
  { name: 'Products', href: './services', current: true },
  { name: 'Our Story', href: './about', current: false },

];

function Navbar() {
  let path = "";
  const location = useLocation();
  // console.log(location)
  path = location.pathname;
  const [scrolllocation, setScrolllocation] = useState(false);

  // const [navcolor,setNavcolor]=useState("")
  document.addEventListener("scroll", (event) => {
    if (window.scrollY > 50) {
      setScrolllocation(true);
    } else {
      setScrolllocation(false);
    }
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div
    className={`isolate sticky top-0 mx-auto grid font-semibold z-50 ${scrolllocation
      ? "bg-gradient-to-r from-green-700 via-brown-500 to-white-800 font-bold text-white"
      : "bg-gradient-to-r from-green-700 via-brown-500 to-white-800 font-white text-white"
    }`}
  >
      <div className="px-6 p-4 lg:px-8">
        <div>
          <nav
            className="flex h-9 items-center justify-between"
            aria-label="Global"
          >
            <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
              <a href="/" className="-m-1.5 p-1.5">
                <img
                  className="rounded-full"
                  src={logo}
                  alt="Bootstrap"
                  width="60"
                  height="60"
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className=" inline-flex items-center justify-center rounded-md  text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
              {navigation.map((item) => (
                <HashLink
                  data-toggle="collapse"
                  key={item.name}
                  to={
                    item === "home" ? item.href + "/#top" : item.href + "#top"
                  }
                  className={`  text-gray-900 hover:text-blue-600`}
                >
                  {item.name}
                </HashLink>
              ))}
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
              <HashLink
                to="/Contactus/#top"
                onClick={() => setMobileMenuOpen(false)}
                className={`inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6  text-gray-600  shadow-sm ring-2 ring-gray-900/10 hover:ring-green-700/25 hover:bg-green-700 hover:text-white`}
              >
                Contact Us
              </HashLink>
            </div>
          </nav>
          {/* mobile */}
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel
              focus="true"
              className="fixed inset-0 z-50 overflow-y-auto bg-white px-6 py-6 lg:hidden"
            >
              <div className="flex h-9 items-center justify-between">
                <div className="flex">
                  <a href="/" className="-m-1.5 p-1.5">
                    <img
                      className="rounded-full"
                      src={logo}
                      alt="Bootstrap"
                      width="60"
                      height="60"
                    />
                  </a>
                </div>
                <div className="flex">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <HashLink
                        smooth
                        to={
                          item === "home"
                            ? item.href + "/#top"
                            : item.href + "#top"
                        }
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                      >
                        {item.name}
                      </HashLink>
                    ))}
                  </div>
                  <div
                    className={`${path === "Contactus " ? "text-white" : "text-gray-600"
                      }py-6`}
                  >
                    <HashLink
                      smooth
                      to="contactus/#top"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                    >
                      Contact us
                    </HashLink>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
