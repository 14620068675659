import React from 'react';
import image from '../../assets/images/bargat_1.png';
import rivanibarga from '../../assets/images/rivanibargat.jpeg';
import Rivani from '../../assets/images/Rivani.png';
import mansibargat from '../../assets/images/mansibargat.jpeg';
import Riabargat from '../../assets/images/Riabargat.jpeg';
import Manisha from '../../assets/images/Manisha_-bargat-photo-transformed (1).png';
import { MapContainer, Marker, Tooltip, useMap, } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import cartoonImage from '../../assets/images/cartoonImage.jpg';
import { Icon } from 'leaflet'
import { useState } from 'react';

function LocationMarker(props) {
    const map = useMap()

    map.flyTo([props.iamlat, props.iamlong], 15)
    const to = [props.iamlat, props.iamlong]
    const from = [32.18342192203705, 76.3296029969974]
    const distance = map.distance(from, to)

    const sorted = String(distance / 1000).slice(0, 4);
    console.log("sorted:", sorted);

    return (

        <Marker position={[props.iamlat, props.iamlong]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
            <Tooltip offset={[0, 20]} opacity={1} >Distance Between Current Location and {props.markername} is {sorted}KMs in CROWFLY</Tooltip>
        </Marker>

    )
}

function About() {

    function myFunction() {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }

    const limeOptions = { color: 'blue' }
    const [markername, setMarkername] = useState("I am a Marker")
    const [circleproperties, setCircleproperties] = useState(null);
    const [iamlat, setIamlat] = useState(32.18342192203705);
    const [iamlong, setIamlong] = useState(76.3296029969974);
    const polyline = [
        [32.18342192203705, 76.3296029969974],
        [iamlat, iamlong],

    ]
    const handlebuttonclick = () => {
        if ("geolocation" in navigator) {

            console.log("Available");
            navigator.geolocation.getCurrentPosition(function (position) {

                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                setIamlat(position.coords.latitude);
                setIamlong(position.coords.longitude);
                setCircleproperties({ lat: position.coords.latitude, lng: position.coords.longitude })


            });
        }

        else {
            console.log("Not Available");
        }

    }
    const handleArea = (lat, lng, name) => {
        setIamlat(lat);
        console.log(lat, lng)
        setIamlong(lng);
        setMarkername(name)
        setCircleproperties(null);
    }
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <>
            <div className="flex flex-col items-center lg:mx-20 ">
                <img
                    className="w-full h-100%"
                    src={image}
                    alt="Image"
                />
            </div>

            {/* <div class="container lg:flex items-center justify-center p-12 lg:mt-8 relative"
                id="contactus"
            > */}
             <div className="text-center">
             <h1 class="text-4xl sm:text-5xl text-green-800 text-center dark:text-white font-extrabold tracking-tight py-2  ">          
             Our Bargat Story
                        </h1>
                {/* <h3 className="text-center font-semibold text-LG/[1px] text-sm/[17px] best centered-div"></h3> */}
            </div>
             
                <div className="container lg:flex items-center justify-center px-5  relative" id="contactus">
                <div className="mx-auto container w-full max-w-[550px]">
                    {isExpanded ? (
                        <>
                            <p>
                            My name is Manisha. I have been working with women and adolescents for about 6 years now. I have always lived in a village. But when I started working in an organization, alongside women and adolescents, I began to understand the situation of women around me. It was during that time that I heard many women say...
                                    "We are not educated, so we cannot do anything."
                                    "If we were well-educated, we could work and earn money, which would help our children receive a good education and succeed in life."
                                    ...and I wondered what could be done to empower these women through work?
                                    We all know that when the lockdown happened, we witnessed various problems everywhere. One of the prominent issues was unemployment. From August 2020, I also joined a group of 9 girls called "Dharachidi." During that time, we started discussing women's issues and the problems faced by women during the lockdown.
                                    We initiated a research project and I decided to focus on women and their work-related issues. It was during this research that I came to know about a girl who, along with her mother, made some products using the leaves of the pine tree. When I spoke to them over the phone, I realized that we both had studied in the same school and knew each other to some extent. I met them and shared my idea of teaching this skill to other women and working together. They agreed to this proposal.
                                    Due to this collaboration, one of my colleagues told me about the SAWFIN fellowship. I applied for the fellowship, and since then, this journey has deepened.
                                    Thanks to the support of SAWFIN, we were able to form our "Bargat" group in April 2021. Initially, we had 8 members in this group, but now we are working with 20 members..
                            </p>
                        </>
                    ) : (
                        <div className="truncate">
                            My name is Manisha. I have been working with women and adolescents for about 6 years now. I have always lived in a village. But when I started working in an organization, alongside women and adolescents, I began to understand the situation of women around me. It was during that time that I heard many women say...
                                    "We are not educated, so we cannot do anything."
                                    "If we were well-educated, we could work and earn money, which would help our children receive a good education and succeed in life."
                                    ...and I wondered what could be done to empower these women through work?
                                    We all know that when the lockdown happened, we witnessed various problems everywhere. One of the prominent issues was unemployment. From August 2020, I also joined a group of 9 girls called "Dharachidi." During that time, we started discussing women's issues and the problems faced by women during the lockdown...

                        </div>
                    )}

                    <button
                        className="text-blue-500 underline"
                        onClick={toggleReadMore}
                    >
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                </div>
                <div className="flex-col lg:w-3/5 justify-center">
                    <div className="relative flex items-center lg:justify-center flex-col min-w-0 break-words w-full">
                        <div className="flex-auto items-center justify-center lg:p-10 lg:m-10">
                            <img
                                src={Manisha}
                                className="lg:h-100 lg:p-15 bg-light"
                                alt="contact image"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container lg:flex items-center justify-center px-5   relative" id="contactus">
                <div class="  flex-col lg:w-3/5 justify-center  lg:-mt-12">
                    <div class="relative flex items-center lg:justify-center py-4 flex-col min-w-0 break-words w-full mt-10">
                        <h1 class="text-4xl sm:text-5xl text-green-800 text-center dark:text-white font-extrabold tracking-tight  ">
                            
                            हमारी बरगत कहानी
                        </h1>
                        <div class="  flex-auto  items-center justify-center  lg:">
                            <img
                                src={cartoonImage}
                                className="lg:h-100"
                                alt="contact image"
                            />
                        </div>
                    </div>
                </div> 
                <div className="mx-auto container w-full max-w-[550px]">
                    {isExpanded ? (
                        <>
                            <p>
                            मेरा नाम मनीषा हैं। मुझे महिलाओं एवं किशोरियों के साथ काम करते हुए लगभग 06 साल हो गए हैं। मैं बचपन से ही गांव में रही हूं। लेकिन जब मैं एक संस्था में काम करती थी, महिलाओं एवं किशोरियों के साथ, तब अपने आसपास की महिलाओं की स्थिति को समझना शुरू किया। उस ही दौरान मैंने जाना की महिलाओं के जीवन में कई तरह की समस्याएं हैं। मैंने बहुत सी महिलाओं को यह बोलते हुए सुना …

                            “हम कम पढ़े लिखे हैं इसलिए हम कुछ नहीं कर पाते हैं”

                            “ अगर हम अच्छे पढ़े लिखे होते तो हम भी नौकरी कर पाते और घर पर पैसा कमा कर ला पाते जिससे हमारे बच्चे भी अच्छे से पढ़ाई-लिखाई करके जिंदगी में आगे बढ़ पाते”

                            …और सोचती थी कि ऐसा क्या किया जाए कि हम इन महिलाओं को किसी काम के साथ जोड़ सकें ?

                            हम सब जानते हैं कि जब लॉकडाउन हुआ तो उस समय हमें हर जगह कई तरह की समस्याएं देखने को मिली। उसमें से रोजगार की समस्या भी बहुत साफ हमारे सामने नजर आ रही थी। 2020 अगस्त से मैं "धारचिड़ी" नाम से एक 09 लड़कियों के समूह में भी जुड़ गयी थी। उस दौरान हम सब महिलाओं के मुद्दों पर एवं लॉकडाउन में हुई महिलाओं की समस्याओं पर चर्चा करने लगे।

                            हमने एक रिसर्च का काम भी शुरू किया, मैं काम और महिला के मुद्धे पे खोज कर रही थी, जिसके दौरान मुझे एक लड़की के बारे में पता चला। वह और उसकी माताजी चीड़ की पत्तियों से घरों पर इस्तेमाल होने वाले कुछ सामान बनाते हैं। जब मैंने उनसे फोन पर बात की तब मुझे पता चला कि हम दोनों ने एक ही स्कूल में पढ़ाई की हैं और कुछ हद तक एक दूसरे को जानते भी थे। मैं उनके साथ मिलने गई तथा अपनी सोच को उनके साथ सांझा किया कि मैं यह चाहती थी कि हम इस काम को दूसरी महिलाओं को भी सिखाएं तथा एक साथ मिलकर काम करें। उन्होंने इस बात पर सहमति दी। इसी के चलते मुझे मेरे “धारचिड़ी” समूह की एक साथी ने SAWFIN की Fellowship के बारे में बताया। मैंने इस fellowship के लिए अप्लाई किया और तब से और गहराई में शुरू हुई यह सफ़र ।

                            SAWFIN के इस सपोर्ट की वजह से हम 2021 अप्रैल में अपने "बरगत" समूह का निर्माण कर पाए। शुरुआत में हम इस समूह में 08 सदस्य थे। लेकिन अब हम इस समूह में 20 सदस्य मिलकर काम कर रहे हैं।
                            </p>
                        </>
                    ) : (
                        <div className="truncate">
                             मेरा नाम मनीषा हैं। मुझे महिलाओं एवं किशोरियों के साथ काम करते हुए लगभग 06 साल हो गए हैं। मैं बचपन से ही गांव में रही हूं। लेकिन जब मैं एक संस्था में काम करती थी, महिलाओं एवं किशोरियों के साथ, तब अपने आसपास की महिलाओं की स्थिति को समझना शुरू किया। उस ही दौरान मैंने जाना की महिलाओं के जीवन में कई तरह की समस्याएं हैं। मैंने बहुत सी महिलाओं को यह बोलते हुए सुना …

                                                                                                                                                                                                                                                                                                                                                                                                                                                    “हम कम पढ़े लिखे हैं इसलिए हम कुछ नहीं कर पाते हैं”

                                                                                                                                                                                                                                                                                                                                                                                                                                                    “ अगर हम अच्छे पढ़े लिखे होते तो हम भी नौकरी कर पाते और घर पर पैसा कमा कर ला पाते जिससे हमारे बच्चे भी अच्छे से पढ़ाई-लिखाई करके जिंदगी में आगे बढ़ पाते”

                                                                                                                                                                                                                                                                                                                                                                                                                                                    …और सोचती थी कि ऐसा क्या किया जाए कि हम इन महिलाओं को किसी काम के साथ जोड़ सकें ?

                                                                                                                                                                                                                                                                                                                                                                                                                                                    हम सब जानते हैं कि जब लॉकडाउन हुआ तो उस समय हमें हर जगह कई तरह की समस्याएं देखने को मिली। उसमें से रोजगार की समस्या भी बहुत साफ हमारे सामने नजर आ रही थी। 2020 अगस्त से मैं "धारचिड़ी" नाम से एक 09 लड़कियों के समूह में भी जुड़ गयी थी। उस दौरान हम सब महिलाओं के मुद्दों पर एवं लॉकडाउन में हुई महिलाओं की समस्याओं पर चर्चा करने लगे।...

                        </div>
                    )}

                    <button
                        className="text-blue-500 underline"
                        onClick={toggleReadMore}
                    >
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            </div>
{/* 
            <section section class="bg-white dark:bg-gray-900" >
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6 ">
                    <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Team</h2>
                        <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400"></p>
                    </div>
                    <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1">
                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                            <img className="rounded-[20px] shadow-2xl lg:w-1/4 " alt="bargat" src={Riabargat} />

                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <a href="/">Manisha</a>
                                </h3>
                                <span class="text-gray-500 dark:text-gray-400">Co-Founder</span>
                                <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                                    My name is Manisha. I have been working with women and adolescents for about 6 years now. I have always lived in a village. But when I started working in an organization, alongside women and adolescents, I began to understand the situation of women around me. It was during that time that I heard many women say...
                                    "We are not educated, so we cannot do anything."
                                    "If we were well-educated, we could work and earn money, which would help our children receive a good education and succeed in life."
                                    ...and I wondered what could be done to empower these women through work?
                                    We all know that when the lockdown happened, we witnessed various problems everywhere. One of the prominent issues was unemployment. From August 2020, I also joined a group of 9 girls called "Dharachidi." During that time, we started discussing women's issues and the problems faced by women during the lockdown.
                                    We initiated a research project and I decided to focus on women and their work-related issues. It was during this research that I came to know about a girl who, along with her mother, made some products using the leaves of the pine tree. When I spoke to them over the phone, I realized that we both had studied in the same school and knew each other to some extent. I met them and shared my idea of teaching this skill to other women and working together. They agreed to this proposal.
                                    Due to this collaboration, one of my colleagues told me about the SAWFIN fellowship. I applied for the fellowship, and since then, this journey has deepened.
                                    Thanks to the support of SAWFIN, we were able to form our "Bargat" group in April 2021. Initially, we had 8 members in this group, but now we are working with 20 members..
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section class="bg-white dark:bg-gray-900">
                <div class="px-4 mx-auto max-w-screen-xl  ">
                    <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1">
                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                            <img className="rounded-[20px] shadow-2xl lg:w-1/4 " alt="bargat" src={rivanibarga} />
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <a href="/">Rivani </a>
                                </h3>
                                <span class="text-gray-500 dark:text-gray-400">Team-Member</span>
                                <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Rivani is a 23-year-old individual who has completed her education up to the 11th grade. She used to think that she too could do a job that would allow her to stand on her own two feet. She learned about the "Bargat" group from Manju and felt great watching them work. However, she never thought that she too could do this kind of work. But when she got the opportunity to work, she learned a lot of things. She had never sent a parcel from the post office before, but through her group, she went to the post office for the first time and learned how to send her belongings. When writing the address on the parcel, she also learned about the information she needed to have. Sending the parcel for the first time made her very happy. The money she earns from making these items helps her cover her expenses, as well as provide for her household needs. She enjoys doing this work.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-white dark:bg-gray-900">
                <div class="px-4 mx-auto max-w-screen-xl  ">
                    <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1">
                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                            <img className="rounded-[20px] shadow-2xl lg:w-1/4 " alt="bargat" src={mansibargat} />
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <a href="/">Manju </a>
                                </h3>
                                <span class="text-gray-500 dark:text-gray-400">Team-Member</span>
                                <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                                    Manju is a 22-year-old who completed her education up to the 12th grade. She has been
                                    working with the &quot;Bargat&quot; group since April 2021. According to her, prior to joining the
                                    group, she was idle at home. The group&#39;s formation has provided her with employment
                                    opportunities, and she has gained a sense of identity through her involvement. As a
                                    result, she has now begun to earn her own income, which allows her to manage her own
                                    expenses without having to rely on her parents for financial assistance. Additionally, by
                                    attending the group&#39;s meetings, she has been able to learn new things such as
                                    discrimination between boys and girls, educational inequality, focusing on health, and
                                    preventing violence. She has also been given the opportunity to observe and understand
                                    the work of others in different locations. As a result, she now trains all of the women in
                                    her group in job skills, which has helped her overcome her fears and gain experience in
                                    teaching job skills to others.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section section class="bg-white dark:bg-gray-900" >
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6 ">
                    <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1">
                        <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                            <img className="rounded-[20px] shadow-2xl lg:w-1/4 " alt="bargat" src={Riabargat} />
                            <div class="p-5">
                                <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <a href="/">Ria</a>
                                </h3>
                                <span class="text-gray-500 dark:text-gray-400">Team-Member</span>
                                <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                                    She is 20 years old and was studying B.A. She says, "I have designed various new products, and I really enjoy doing it. I want to learn more about designing new products, especially incorporating the use of leaflets from the cedar tree. Along with that, I had the opportunity to visit the Roots Community (an art and craft store) and explore different things. I also saw other people making efforts to improve their work. I hope to come up with something beneficial for myself in that direction. Currently, I am learning to post on Instagram, share stories, and create small videos. I am enjoying these activities a lot. I also went to the Baitjikli (Baijnath) group to train other colleagues. There, I discovered that women are also doing excellent work, and we need to focus on what else we can do together.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section > */}


<section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-green-800 dark:text-white">Our Team</h2>
          <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Our team is a dynamic and collaborative group of individuals working together to achieve shared goals.</p>
      </div> 
      <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
          {/* <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
              <img style={{width:'385px',height:'220px'}} className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg " alt="riya" src={Riabargat} />

                  
              </a>
              <div class="p-5">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">Manisha Choudhary</a>
                  </h3>
                  <span class="text-gray-500 dark:text-gray-400">Founder</span>
                  <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Bonnie drives the technical strategy of the flowbite platform and brand.</p>
               
              </div>
          </div>  */}
          <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
              <img style={{width:'385px'}} className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg " alt="riya" src={Rivani} />
              </a>
              <div class="p-5">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">Rivani Choudhary</a>
                  </h3>
                  <span class="text-gray-500 dark:text-gray-400">Artist</span>
                  <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Rivani, empowered and confident through Bargat group. Learns practical skills, finds joy in earning.</p>
               
              </div>
          </div> 
          <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
              <img style={{width:'385px'}} className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg " alt="riya" src={Riabargat} />
              </a>
              <div class="p-5">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">Riya Choudhary</a>
                  </h3>
                  <span class="text-gray-500 dark:text-gray-400">Artist</span>
                  <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">A passionate young designer exploring new product possibilities, embracing creativity.</p>
               
              </div>
          </div> 
          <div class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
              <img style={{width:'385px'}} className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg " alt="riya" src={mansibargat} />
              </a>
              <div class="p-5">
                  <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                      <a href="#">Manju Choudhary</a>
                  </h3>
                  <span class="text-gray-500 dark:text-gray-400">Artist</span>
                  <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">Manju gains empowerment, employment, and financial independence with the Bargat group.</p>
              
              </div>
          </div>  
      </div>  
  </div>
</section>


            
        </>
    )
}

export default About