import React from 'react'

function Thanks() {
    return (
        <div class="bg-white w-full px-16 md:px-0 py-24 flex items-center justify-center">
            <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-12 py-8 rounded-lg shadow-2xl">
                <p class="text-2xl md:text-3xl lg:text-xl font-bold tracking-wider text-gray-500 mt-4">Thank you for contacting us. We value your feedback.
!</p>
                <p class="text-gray-500 mt-4 pb-4 border-b-2 text-center">
                    We appreciate you contacting us. One of our colleagues will get back in touch with you soon!Have a great day!</p>
                <a href="/" class="flex items-center space-x-2 bg-green-700 hover:bg-green-600 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150" title="Return Home">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                    </svg>
                    <span>Return Home</span>
                </a>
            </div>
        </div>
    )
}

export default Thanks