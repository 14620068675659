import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {


    faWhatsapp,
    faMarkdown,

} from "@fortawesome/free-brands-svg-icons";
// import About from './About';
import { HashLink } from 'react-router-hash-link';
import ScrollToTop from "react-scroll-to-top";

function Footer() {
    return (
        <>



            <footer class="">

                <div class="max-w-screen-xl px-4 lg:py-16  mx-auto sm:px-6 lg:px-8 mt-4">
                    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <div className='col-span-1'>
                            <div className="">
                                <div class='text-3xl font-bold'>Bargat</div>
                            </div>
                            <p class="max-w-xs mt-4 text-sm text-gray-600">
                                We use Pine tree leaves to make household items because they are abundant in Himachal Pradesh. Every year in March and April, the leaves fall, creating a thick layer that prevents other plants from growing. These leaves cause forest fires, harming wildlife and damaging the environment.                            </p>
                            <div class="flex mt-4 lg:space-x-6 md: space-x-3 text-gray-600">
                                {/* <a class="hover:opacity-75" href="/" target="_blank" rel="noreferrer">
                                    <span class="sr-only"> WhatsApp </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="currentColor">
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                                    </svg>

                                </a> */}

                                {/* <a class="hover:opacity-75" href="/" target="_blank" rel="noreferrer">
                                    <span class="sr-only"> Twitter </span>
                                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>
                                </a> */}
                                <a class="hover:opacity-75" href="https://www.instagram.com/bargat_janglo_se/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noreferrer">
                                    <span class="sr-only"> Instagram </span>
                                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                                    </svg>
                                </a>
                                {/* <a class="hover:opacity-75" href target="_blank" rel="noreferrer">
                                    <span class="sr-only"> Facebook </span>
                                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                                    </svg>
                                </a> */}
                            </div>
                        </div>
                        <div class="lg:grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
                            <div>
                                <p class="font-medium">
                                    Company
                                </p>
                                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                                    <HashLink smooth to="/#top" ><p className="hover:text-blue-800 ">Home</p></HashLink>
                                    <HashLink smooth to="/about/#top" ><p className="hover:text-blue-800 ">About Us</p></HashLink>
                                    <HashLink smooth to="/contactus/#top" ><p className="hover:text-blue-800 ">Contact us</p></HashLink>
                                    {/* <HashLink smooth to="/about/#ourteam"><p className="hover:text-blue-800 "> Meet the Team</p></HashLink> */}
                                </nav>
                            </div>
                            <div>
                                <p class="font-medium ">
                                    Services
                                </p>
                                <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                                    <p class=' '  ><FontAwesomeIcon icon={faWhatsapp} />+91 9015234874</p>
                                    <p class=' '><FontAwesomeIcon icon={faWhatsapp} />+91 9882512357  </p>
                                    {/* <Link to="/" ><p className="hover:text-blue-800 ">Privacy-Policy</p></Link> */}
                                    <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target='blank' class="text-green-800">bargatcollective@gmail.com    </a>.
                                </nav>
                            </div>
                            <div className='lg:col-span-2'>
                                <p class="font-medium">
                                    Get in Touch with us
                                </p>
                                <nav class="flex flex-col space-y-2 text-sm mt-4 text-gray-500">
                                    <div>
                                        {/* <p class=' text-blue-800' ><FontAwesomeIcon icon={faMarkdown} /> USA (New York)</p>
                                        <p class='my-1' > 1035 Old Country Rd, Westbury,</p>
                                        <p class='mt-1' >NY 11590, United States </p> */}
                                        <p class='text-green-800' ><FontAwesomeIcon icon={faMarkdown} /> India (Kangra)</p>
                                        <p class=''>V.P.O Ladwara Teh. Shahpur District Kangra 176208
                                            Himachal Pradesh </p>
                                    </div>
                                </nav>
                            </div>



                        </div>
                    </div>
                    <p class="mt-4 text-xs text-gray-800">
                        Bargatcollective  © 2023 All Right Reserved.
                    </p>
                </div>
            </footer>






            <ScrollToTop
                smooth
                // viewBox="0 0 30 30"
                // svgPath="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                component={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" className="w-6 h-6">
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clipRule="evenodd" />
                    </svg>
                }

            />





        </>
    )
}

export default Footer