import About from './Components/Viewers/About';
import './App.css';
import Home from './Components/Viewers/Home';
import Services from './Components/Viewers/Product';
import Footer from './Components/Viewers/Footer';
import Example from './Components/Viewers/Navbarnew';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Contactus from './Components/Contactus';

function App() {
  return (

    <>

      <BrowserRouter>
        <Example />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/Contactus" element={<Contactus />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>

  );
}

export default App;
