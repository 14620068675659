import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Thanks from './Thanks';

function ContactUs() {
  const form = useRef();
  const [isSubmit, setIsSubmit] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hjwy27r",
        "template_dz44djn",
        form.current,
        "hZJWixCMtPRvdqVkS"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmit(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset ()
  };

  const handleContct = (e) => {
    e.preventDefault();
    setIsSubmit(true);
  };

  return (
    <>
      <div className='mainForm'>
        {!isSubmit ? (
          <>
            <div class="container lg:flex items-center justify-center p-12 lg:mt-8 relative" id='contactus'>
              <div class="  flex-col lg:w-3/5 justify-center  lg:-mt-12">
                <div class="relative flex items-center lg:justify-center py-4 flex-col min-w-0 break-words w-full">
                  <h1 class="text-4xl sm:text-5xl text-gray-800 text-center dark:text-white font-extrabold tracking-tight">
                    Get in touch
                  </h1>
                  <p class="text-normal text-lg sm:text-2xl text-center  font-medium text-gray-600 dark:text-gray-400 mt-2">
                    Fill in the form to start a conversation
                  </p>

                  <div class="  flex-auto  items-center justify-center lg:p-5 lg:p-10">
                    <img src="https://img.freepik.com/free-vector/flat-illustration-secretary-s-day-celebration_52683-107968.jpg" className="lg:h-80" alt="contact image" />
                  </div>
                </div>

              </div>
              <div class="mx-auto container w-full max-w-[550px]">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  classname="form__original"
                >
                  <div class="mb-5">
                    <label
                      for="name"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      id="name"
                      placeholder="Full Name"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      required
                    />
                  </div>
                  <div class="mb-5">
                    <label
                      for="email"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="user_email"
                      id="email"
                      placeholder="Enter your email"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none "
                      required
                    />
                  </div>
                  <div class="mb-5">
                    <label
                      for="subject"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Enter your subject"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      required
                    />
                  </div>
                  <div class="mb-5">
                    <label
                      for="message"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Message
                    </label>
                    <textarea
                      rows="4"
                      name="message"
                      id="message"
                      placeholder="Type your message"
                      class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      required
                    ></textarea>
                  </div>
                  <div className="mt-3">
                    <button
                      type="submit "
                      class="hover:shadow-form rounded-md bg-green-700 py-3 px-8 text-base font-semibold text-white outline-none "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <Thanks />
        )}
      </div>
    </>
  );
}

export default ContactUs;




