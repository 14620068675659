import React from 'react'
import rotibox from '../../assets/images/rotibox.jpeg'
import rotibox1 from '../../assets/images/rotibox1.jpeg';
import rotibox2 from "../../assets/images/rotibox2.jpeg";
import rotibox3 from "../../assets/images/rotibox3.png";
import rotibox4 from "../../assets/images/rotibox4.jpeg";
import mirror from "../../assets/images/mirror.png";
import laundarybasket from "../../assets/images/laundarybasket.png";
import keychains from "../../assets/images/keychains.png";
import jewllerybox2 from "../../assets/images/jewllerybox2.jpeg";
import jewellerybox from "../../assets/images/jewellerybox.jpeg";
import ecocase from "../../assets/images/ecocase.png";
import earing1 from "../../assets/images/earing1.png";
import earing2 from "../../assets/images/earing2.png";
import earing3 from "../../assets/images/earing3.png";
import doormate from "../../assets/images/doormate.png";
import coasterset from "../../assets/images/coasterset.jpeg";
import bottlescover from "../../assets/images/bottlescover.jpeg";


const products = [
    {
        id: 1,
        name: 'Roti Box',
        prize: '₹ 850.00',
        image: rotibox,
    },
    {
        id: 2,
        name: 'Mirror',
        prize: '₹ 900.00',
        image: mirror,
    },
    {
        id: 3,
        name: 'Laundary Basket',
        prize: '₹ 2000.00',
        image: laundarybasket,
    },
    {
        id: 4,
        name: 'keychains',
        prize: '₹ 130.00',
        image: keychains,
    },
    {
        id: 5,
        name: 'Roti Box',
        prize: '₹ 750.00',
        image: rotibox4,
    },
    {
        id: 6,
        name: 'Earings',
        prize: '₹ 150.00',
        image: earing1,
    },
    {
        id: 7,
        name: 'Bottles Cover',
        prize: '₹ 400.00',
        image: bottlescover,
    },
    {
        id: 8,
        name: 'Earings',
        prize: '₹ 150.00',
        image: earing3,
    },
    {
        id: 9,
        name: 'Pen Stand',
        prize: '400.00',
        image: ecocase,
    },
    {
        id: 10,
        name: 'Earings',
        prize: '₹ 150.00',
        image: earing2,
    },
    {
        id: 11,
        name: 'Roti Box',
        prize: '₹ 800.00',
        image: rotibox3,
    },
    {
        id: 12,
        name: 'Coaster Set',
        prize: '₹ 550.00',
        image: coasterset,
    },
    {
        id: 13,
        name: 'Roti Box',
        prize: '₹ 750.00',
        image: rotibox2,
    },
    {
        id: 14,
        name: 'Doormate',
        prize: '₹ 750.00',
        image: doormate,
    },
    {
        id: 15,
        name: 'Coaster Set',
        prize: '₹ 550.00',
        image: coasterset,
    },
    {
        id: 16,
        name: 'Jwellery Box',
        prize: '₹ 550.00',
        image: jewellerybox,
    },
    {
        id: 17,
        name: 'Jwellery Box',
        prize: '₹ 550.00',
        image: jewllerybox2,
    },
    {
        id: 18,
        name: 'Roti Box',
        prize: '₹ 850.00',
        image: rotibox1,
    },

];

function Product() {
    return (
        <>
            <section className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
                <div className="text-center pb-12">
                    <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl font-heading text-green-900 animate-charcter">
                         Our Awesome Handmade Products
                    </h1>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {products.map((product) => (
                        <div
                            key={product.id}
                            className="w-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col justify-center items-start bg-white dark:bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg"
                        >
                            <div>
                                <img
                                    className="object-center object-cover h-auto w-full"
                                    src={product.image}
                                    alt="photo"
                                />
                            </div>
                            <div className="py-8 sm:py-6 p-3">
                                <h2 class="mb-2 text-lg font-medium dark:text-white text-gray-600"> {product.name}</h2>
                                <p class="mr-2 text-lg font-semibold text-gray-900 dark:text-white"> {product.prize}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}

export default Product;
